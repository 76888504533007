<template>
  <DefaultSelect
    v-bind="$attrs"
    label="Który poród"
    placeholder="Kliknij by wybrać..."
    :value.sync="selectedProxy"
    :items="items"
    return-object
    hide-details
    noMargins
  >
  </DefaultSelect>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      items: [
        { text: "1 - Pierwszy", value: 1 },
        { text: "2 - Drugi", value: 2 },
        { text: "3 - Trzeci", value: 3 },
        { text: "4 - Czwarty", value: 4 },
        { text: "5 - Piąty", value: 5 },
        { text: "6 - Szósty", value: 6 },
        { text: "7 - Siódmy", value: 7 },
        { text: "8 - Ósmy", value: 8 },
        { text: "9 - Dziewiąty", value: 9 },
        { text: "10 - Dziesiąty", value: 10 },
        { text: "11 - Jedenasty", value: 11 },
        { text: "12 - Dwunasty", value: 12 },
        { text: "13 - Trzynasty", value: 13 },
        { text: "14 - Czternasty", value: 14 },
        { text: "15 - Piętnasty", value: 15 },
        { text: "16 - Szesnasty", value: 16 },
        { text: "17 - Siedemnasty", value: 17 },
        { text: "18 - Osiemnasty", value: 18 },
        { text: "19 - Dziewiętnasty", value: 19 },
        { text: "20 - Dwudziesty", value: 20 },
      ],
    };
  },
  computed: {
    selectedProxy: {
      get() {
        const index = _.findIndex(this.items, { value: this.value });
        if (index !== -1) {
          return this.items[index];
        } else {
          return this.items[0];
        }
      },
      set(item) {
        this.$emit("input", item.value);
      },
    },
  },
  components: {
    DefaultSelect: () => import("@/components/selects/DefaultSelect"),
  },
};
</script>